import React, { useEffect, useRef } from "react";
import useMousePosition from "../hooks/useMousePosition";

interface Coordinates {
  x: number | null;
  y: number | null;
}

const offset = Array.from({ length: 1000 }, () =>
  Math.floor(Math.random() * 40)
);

function positionModifier(position: number | null): number {
  const modifier = 0.5;
  const offset = modifier * (position ? position / window.innerWidth : 0);
  return offset;
}

function mousePositionOffset(mousePosition: Coordinates): [number, number] {
  const xOffset = positionModifier(mousePosition.x);
  const yOffset = positionModifier(mousePosition.y);
  return [xOffset, yOffset];
}

function orbitingItemCoordinates(
  canvas: HTMLCanvasElement,
  index: number,
  time: number,
  mousePosition: Coordinates
) {
  const [xOffset, yOffset] = mousePositionOffset(mousePosition);

  const b = time / 9 + index * index;
  const v = 100 + offset[index];
  const xPos = canvas.height / 2 + v * Math.sin(b - xOffset);
  const yPos = canvas.width / 2 + v * Math.cos(b + 2 + yOffset);
  const radius = 3 - Math.cos(b) * 3;

  return [xPos, yPos, radius];
}

function drawCentralMass(ctx: CanvasRenderingContext2D) {
  // Gradient for central mass
  var gradient = ctx.createLinearGradient(200, 200, 250, 350);
  gradient.addColorStop(0, "#04d9ff");
  gradient.addColorStop(1, "transparent");

  // Central mass
  ctx.beginPath();
  ctx.arc(250, 250, 50, 0, 2 * Math.PI);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.closePath();
}

function drawObitingItem(
  ctx: CanvasRenderingContext2D,
  xPos: number,
  yPos: number,
  radius: number
) {
  ctx.beginPath();
  ctx.arc(xPos, yPos, radius * 0.5, 0, 2 * Math.PI);
  ctx.fillStyle = "#4a4a4a";
  ctx.fill();
  ctx.closePath();
}

const OrbtiAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const mousePosition: Coordinates = useMousePosition();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let animationFrameId: number;

    const draw = (t: number) => {
      // Reduce rotation speed of orbit
      t = t / 1000;

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      drawCentralMass(ctx);

      // Loop through orbiting items
      for (let i = 1000; i > 0; i--) {
        const [x, y, r] = orbitingItemCoordinates(canvas, i, t, mousePosition);

        drawObitingItem(ctx, x, y, r);
      }

      // Request the next frame
      animationFrameId = requestAnimationFrame(draw);
    };

    // Start the animation
    animationFrameId = requestAnimationFrame(draw);

    return () => {
      // Clean up on component unmount
      cancelAnimationFrame(animationFrameId);
    };
  }, [mousePosition]);

  return <canvas ref={canvasRef} width={500} height={500} />;
};

export default OrbtiAnimation;
