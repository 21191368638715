import "./App.css";
import ComplexAnimation from "./components/ComplexAnimation";

function App() {
  return (
    <>
      <div className="noise"></div>
      <div className="App">
        <div className="Header-Container">
          <div className="Header-Name-Container">
            <div style={{ marginRight: "auto" }}>
              <p className="Header-Name">Ron Autenrieb</p>
            </div>
            <p className="Header-Subname">Information Systems @ TUM</p>
          </div>
          <ComplexAnimation />
          <div className="Header-Link-Container">
            <a href="mailto:ron.autenrieb@gmail.com" className="Header-Link">
              Mail
            </a>
            <a
              href="https://www.linkedin.com/in/ron-autenrieb-4a4a16152/"
              className="Header-Link">
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
